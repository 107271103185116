import React from "react";
import "./contactsPage.css"
import Header from "../../components/header/header";
import { useParams } from "react-router-dom";
import instagram from "../../images/instagram.svg"
import telegram from "../../images/telegram.svg"


function ContactsPage() {

    const params = useParams()

    function setMenuLang() {
        if (params.lang === "ru") {
            return "Контакты"
        } else if (params.lang === "uz") {
            return "Kontaktlar"
        } else if (params.lang === "en") {
            return "Contacts"
        }
    }

    function setAddressLang() {
        if (params.lang === "ru") {
            return "Самарканд, улица Шахруха Мирзы, 41 Ориентир: Кооперативный институт"
        } else if (params.lang === "uz") {
            return "st. Shoxruh Mirzo 41-bino Orientir: Kooperativ instituti"
        } else if (params.lang === "en") {
            return "st. Shokhrukh Mirzo building 41 Landmark: Cooperative Institute"
        }
    }

    return (
        <>
            <Header showCatalogList={false} title={setMenuLang()} />
            <main className="main">
                <div className="contacts__wrapper">
                    <div className="social-net__wrapper">
                        <a className="social-net contacts__instagram" target="_blank" href="https://www.instagram.com/blinniy_domik/">
                            <img src={instagram} alt="instagram" />
                        </a>
                        <a className="social-net contacts__telegram" target="_blank" href="https://t.me/blinniy_domik">
                            <img src={telegram} alt="telegram" />
                        </a>
                    </div>
                    <a className="contacts__tel" href="tel:+998906008103">+998 (90) 600 81 03</a>
                    <p className="contacts__address">
                        {setAddressLang()}
                    </p>
                </div>

                <div className="map">
                    <div style={{ position: "relative", overflow: "hidden" }}>
                        <a href="https://yandex.uz/maps/org/165320638857/?utm_medium=mapframe&utm_source=maps"
                            style={{ color: "#eee", fontSize: "12px", position: "absolute", top: "0px" }}>
                            Блинный домик
                        </a>
                        <a href="https://yandex.uz/maps/10334/samarkand/category/cafe/184106390/?utm_medium=mapframe&utm_source=maps"
                            style={{ color: "#eee", fontSize: "12px", position: "absolute", top: "14px" }}>
                            Кафе в Самарканде
                        </a>
                        <a href="https://yandex.uz/maps/10334/samarkand/category/fast_food/184106386/?utm_medium=mapframe&utm_source=maps"
                            style={{ color: "#eee", fontSize: "12px", position: "absolute", top: "28px" }}>
                            Быстрое питание в Самарканде
                        </a>
                        <iframe src="https://yandex.uz/map-widget/v1/?ll=66.959708%2C39.652111&mode=search&oid=165320638857&ol=biz&z=16"
                            width="100%" height="500" frameBorder="o" allowFullScreen={true} style={{ position: "relative" }}></iframe>
                    </div>
                </div >
            </main >
        </>
    )
}


export default ContactsPage