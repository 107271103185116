import React from "react";
import "./menuPage.css"
import Header from "../../components/header/header";
import { Link, useParams } from "react-router-dom";

import breakfast from "../../images/category/breakfast.webp"
import hotdishes from "../../images/category/hotdishes.webp"
import drinks from "../../images/category/drinks.webp"
import salads from "../../images/category/salads.webp"
import other from "../../images/category/other.webp"



function MenuPage() {

    const params = useParams()

    function setMenuLang() {
        if (params.lang === "ru") {
            return "Меню"
        } else if (params.lang === "uz") {
            return "Menyu"
        } else if (params.lang === "en") {
            return "Menu"
        }
    }

    const catalogList = [
        {
            url: "breakfast",
            img: breakfast,
            ru: "Завтрак",
            uz: "Nonushta",
            en: "Breakfast"
        },
        {
            url: "hotdishes",
            img: hotdishes,
            ru: "Горячие блюда",
            uz: "Issiq ovqatlar",
            en: "Hot dishes"
        },
        {
            url: "drinks",
            img: drinks,
            ru: "Напитки",
            uz: "Ichimliklar",
            en: "Drinks"
        },
        {
            url: "salads",
            img: salads,
            ru: "Салаты",
            uz: "Salatlar",
            en: "Salads"
        },
        {
            url: "other",
            img: other,
            ru: "Хлеб и прочее",
            uz: "Non va boshqa narsalar",
            en: "Bread and stuff"
        }
    ]

    return (
        <>
            <Header showCatalogList={false} title={setMenuLang()} />
            <main className="main">
                <div className="menu-list">
                    {catalogList.map((item, i) => (
                        <Link key={i} to={`/${params.lang}/${item.url}`}
                            className="menu-item"
                            style={{ backgroundImage: `url(${item.img})` }}>
                            {item[params.lang]}
                        </Link>
                    ))}
                </div>
            </main>
        </>
    )
}

export default MenuPage