import React from "react";
import "./header.css"
import backArrow from "../../images/arrow-left.svg"
import { Link, useNavigate, useParams } from "react-router-dom";

function Header(props) {
    const params = useParams()
    const navigate = useNavigate();


    function setContactsLinkLang() {
        if (params.lang === "ru") {
            return "Контакты"
        } else if (params.lang === "uz") {
            return "Kontaktlar"
        } else if (params.lang === "en") {
            return "Contacts"
        }
    }


    const catalogList = [
        {
            url: "breakfast",
            ru: "Завтрак",
            uz: "Nonushta",
            en: "Breakfast"
        },
        {
            url: "hotdishes",
            ru: "Горячие блюда",
            uz: "Issiq ovqatlar",
            en: "Hot dishes"
        },
        {
            url: "drinks",
            ru: "Напитки",
            uz: "Ichimliklar",
            en: "Drinks"
        },
        {
            url: "salads",
            ru: "Салаты",
            uz: "Salatlar",
            en: "Salads"
        },
        {
            url: "other",
            ru: "Хлеб и прочее",
            uz: "Non va boshqa narsalar",
            en: "Bread and stuff"
        }
    ]

    return (
        <header className="header">
            <div className="header__title-wrapper">
                <h1 className="header__title">
                    {props.title}
                </h1>
                <img className="back-arrow" src={backArrow} alt="back" onClick={() => navigate(-1)} />
            </div>
            <Link className="header__contacts-link" to={`/${params.lang}/contacts`}>
                {setContactsLinkLang()}
            </Link>
            {props.showCatalogList ?
                <ul className="header__catalog-list">
                    {catalogList.map((item, i) => (
                        <li key={i} className="header__catalog-item">
                            <Link className="header__catalog-link" to={`/${params.lang}/${item.url}`}>
                                {item[params.lang]}
                            </Link>
                        </li>
                    ))}
                </ul>
                : <></>}



        </header>
    )
}

export default Header