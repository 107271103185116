import React, { useEffect, useState } from "react";
import Header from "../../components/header/header";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./catalogPage.css"
import Card from "../../components/card/card";

function CatalogPage() {
    const params = useParams()
    const [data, setData] = useState()

    const catalogList = [
        {
            url: "breakfast",
            ru: "Завтрак",
            uz: "Nonushta",
            en: "Breakfast"
        },
        {
            url: "hotdishes",
            ru: "Горячие блюда",
            uz: "Issiq ovqatlar",
            en: "Hot dishes"
        },
        {
            url: "drinks",
            ru: "Напитки",
            uz: "Ichimliklar",
            en: "Drinks"
        },
        {
            url: "salads",
            ru: "Салаты",
            uz: "Salatlar",
            en: "Salads"
        },
        {
            url: "other",
            ru: "Хлеб и прочее",
            uz: "Non va boshqa narsalar",
            en: "Bread and stuff"
        }
    ]

    function setCatalogTitle() {
        const item = catalogList.find(item => params.catalog === item.url);
        if (item) {
            return item[params.lang];
        }
    }



    useEffect(() => {
        axios.get(`https://menu.blinniy-domik.uz/admin/api/${params.catalog}-api.php`)
            .then(res => {
                setData(res.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false

    return (
        <>
            <Header showCatalogList={true} title={setCatalogTitle()} />
            <main className="main">
                <div className="catalog-list">
                    {
                        data.map((item, i) => (
                            <Card key={i} data={item} />
                        ))
                    }
                </div>
            </main>
        </>
    )
}


export default CatalogPage