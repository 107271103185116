import React from "react";
import './mainPage.css'
import { Link } from "react-router-dom";
import logo from "../../images/logo.png"

import RU from "../../images/RU.svg"
import UZ from "../../images/UZ.svg"
import GB from "../../images/GB.svg"


function MainPage() {
    return (
        <main className="main">
            <div className="logo-wrapper">
                <img className="logo" src={logo} alt="Logo" />
            </div>
            <div className="lang-wrapper">
                <Link className="lang-btn" to="/ru">
                    <img className="lang-flag" src={RU} alt="ru" />
                    Русский
                </Link>
                <Link className="lang-btn" to="/uz">
                    <img className="lang-flag" src={UZ} alt="uz" />
                    O'zbekcha
                </Link>
                <Link className="lang-btn" to="/en">
                    <img className="lang-flag" src={GB} alt="bg" />
                    English
                </Link>
            </div>
        </main>
    )
}


export default MainPage